import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Flex,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Image,
  Input,
  Icon,
  List,
  HStack,
  InputGroup,
  InputRightElement,
  useToast,
  Tooltip,
  useBreakpointValue
} from '@chakra-ui/react';
import { SmallCloseIcon, CheckCircleIcon, WarningIcon, WarningTwoIcon, Search2Icon } from '@chakra-ui/icons';
import { formatNumber, trunkAddress } from './utils';

const Search = ({ addPairToList, selectedPairs }) => {
  // State Variables
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [trendingCoins, setTrendingCoins] = useState([]);
  const pageSize = 5;

  const toast = useToast();

  // Fetch trending coins from the API and update every 10 minutes
  useEffect(() => {
    const fetchTrendingCoins = async () => {
      try {
        const coins = await getTrendingCoins();
        setTrendingCoins(coins);
      } catch (error) {
        console.error('Error fetching trending coins:', error);
      }
    };

    fetchTrendingCoins(); // Fetch coins when the component mounts

    const interval = setInterval(fetchTrendingCoins, 10 * 60 * 1000); // Set up a timer to refetch coins every 10 minutes

    return () => clearInterval(interval); // Clean up the interval when the component unmounts to prevent memory leaks
  }, []);

  // Handlers
  const handleInputChange = (event) => setSearchQuery(event.target.value);
  const handleClearInput = () => clearSearchResults();
  const handleBadgeClick = (badge) => handleSearch(badge);
  const handleKeyPress = (event) => event.key === 'Enter' && handleSearch(searchQuery);
  const handlePageChange = (page) => setCurrentPage(page);

  // Helper Functions
  const isSameBaseQuotePair = (pair1, pair2) => pair1.baseToken.symbol === pair2.baseToken.symbol && pair1.quoteToken.symbol === pair2.quoteToken.symbol;
  const isSamePair = (pair1, pair2) => pair1.baseToken.symbol === pair2.baseToken.symbol && pair1.quoteToken.symbol === pair2.quoteToken.symbol && pair1.dexId === pair2.dexId && pair1.chainId === pair2.chainId && pair1.dexId === pair2.dexId && pair1.pairCreatedAt === pair2.pairCreatedAt && pair1.liquidity.usd === pair2.liquidity.usd;
  const getPageResults = () => searchResults.slice((currentPage - 1) * pageSize, (currentPage - 1) * pageSize + pageSize);
  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  // Fetch trending coins from coingecko
  const getTrendingCoins = async () => {
    try {
      const response = await axios.get('https://api.coingecko.com/api/v3/search/trending');
      return response.data.coins.map((coin) => coin.item.symbol);
    } catch (error) {
      console.error(error);
    }
  };

  // Clear search results
  const clearSearchResults = () => {
    setSearchResults([]);
    setSearchQuery('');
    setCurrentPage(1);
  };
  

  const handleSearch = async (query) => {
    if (query === '') {
      setSearchResults([]);
      return;
    }

    try {
      const response = await axios.get(`https://api.dexscreener.com/latest/dex/search/?q=${query}`);
      const results = response.data.pairs;

      if (Array.isArray(results) && results.length > 0) {
        setSearchResults(results);
        setCurrentPage(1);
      } else {
        setSearchResults([]);
      }
    } catch (error) {
      setError('Error retrieving search results. Please try again.');
    }
  };

  const handlePairSelect = (result) => {
    const alreadyAdded = selectedPairs.some((pair) => isSamePair(pair, result));

    if (alreadyAdded) {
      showPairAlreadyAddedToast();
      return;
    }

    if (selectedPairs.length > 0) {
      const firstPairInList = selectedPairs[0];
      if (!isSameBaseQuotePair(firstPairInList, result)) {
        showNotSamePairToast(result.baseToken.symbol, result.quoteToken.symbol);
        return;
      }
    }

    if (selectedPairs.length === 0) {
      addPairToList(result);
      showBundleStartedToast(result.baseToken.symbol, result.quoteToken.symbol);
      return;
    }

    addPairToList(result);
    showPairAddedToast();
  };

  // Toast Messages
  const showBundleStartedToast = (baseToken, quoteToken) => toast({ title: `${baseToken}/${quoteToken} Bundle Started`, description: `You can now add more ${baseToken}/${quoteToken} pairs bundle.`, status: 'info', duration: 2000, position: 'bottom-left', isClosable: true });
  const showPairAddedToast = () => toast({ title: `Pair added to bundle`, status: 'info', duration: 2000, position: 'bottom-left', isClosable: true });
  const showNotSamePairToast = (baseToken, quoteToken) => toast({ title: `Not same pair`, description: `This pair is ${baseToken}/${quoteToken}.`, status: 'error', duration: 2000, position: 'bottom-left', isClosable: true });
  const showPairAlreadyAddedToast = () => toast({ title: 'Pair already added', description: 'This pair is already added to the bundle.', status: 'error', duration: 2000, position: 'bottom-left', isClosable: true });

  // Render Component
  return (
    <Box>
      <HStack p="8px" w="full" >
        <InputGroup  flex="1">
          <Input type="text" size="md" color="white" bg="gray.800" placeholder="Search by ticker or token address..." value={searchQuery} onChange={handleInputChange} onKeyPress={handleKeyPress} />
          <InputRightElement onClick={handleClearInput}><SmallCloseIcon /></InputRightElement>
        </InputGroup>
        <Button leftIcon={<Search2Icon />} bg="gray.600" size="md" flexShrink="0" onClick={() => handleSearch(searchQuery)}>Search</Button>
      </HStack>
      <HStack ml="4px" p="4px" spacing={1}>
  <Text fontSize="sm" color="gray.400">Trending</Text>
  {isSmallScreen 
    ? trendingCoins.slice(0, 5).map((coin) => (
        <Button key={coin} ml="2px" p="4px" size="xs" colorScheme="gray" onClick={() => handleBadgeClick(coin)}>
          {coin}
        </Button>
      ))
    : trendingCoins.map((coin) => (
        <Button key={coin} ml="2px" p="4px" size="xs" colorScheme="gray" onClick={() => handleBadgeClick(coin)}>
          {coin}
        </Button>
      ))
  }
</HStack>
      {error && <p>{error}</p>}
      {searchResults.length === 0 ? <Flex justify="center" align="center"></Flex> : <List pt="8px" pl="8px" pr="8px">{getPageResults().map((result, index) => 
      <Card key={index} size="sm" bg="gray.800" direction={{ base: 'column', sm: 'row' }} overflow="hidden" variant="filled" mb="1" alignItems="center">
        <CardHeader w="140px">
          <Text fontSize="sm" as="div">{result.baseToken.symbol} / {result.quoteToken.symbol}</Text>
          <HStack>
            <Image src={`https://dd.dexscreener.com/ds-data/chains/${result.chainId}.png`} boxSize="16px" />
            <Image src={`https://dexscreener.com/img/dexes/${result.dexId}.png`} boxSize="16px" />
          </HStack>
        </CardHeader>
      <CardBody>
        <Text fontSize="sm">{result.priceNative} {result.quoteToken.symbol}/{result.baseToken.symbol} {result.liquidity?.usd > 100000 ? <Box as="span" display="inline-flex" alignItems="center"><Icon color="green.400" m="4px" as={CheckCircleIcon} />
        </Box> : result.liquidity?.usd > 10000 ? 
        <Box as="span" display="inline-flex" alignItems="center">
          <Icon color="yellow.400" m="4px" as={WarningIcon} />
          </Box> : 
          <Box as="span" display="inline-flex" alignItems="center">
            <Icon color="red.400" m="4px" as={WarningTwoIcon} />
          </Box>}{formatNumber(result.liquidity?.usd)} USD</Text>
          <Tooltip hasArrow bg='gray.900' color="white" label={result.baseToken.address}>
            <Text fontSize="sm">{result.baseToken.symbol} address: {isSmallScreen ? trunkAddress(result.baseToken.address) : result.baseToken.address}
            </Text>
            </Tooltip>
            </CardBody>
            <CardFooter>
              <Button size="xs" variant="solid" onClick={() => handlePairSelect(result)}>Add to Bundle</Button>
              </CardFooter>
              </Card>
              )}
              </List>}
      {searchResults.length > pageSize && <Flex justify="center" mt="4">{Array.from({ length: Math.ceil(searchResults.length / pageSize) }, (_, index) => <Button key={index} mx="1" variant={currentPage === index + 1 ? 'solid' : 'outline'} onClick={() => handlePageChange(index + 1)}>{index + 1}</Button>)}</Flex>}
    </Box>
  );
};

export default Search;
