import React, { useEffect } from 'react';
import { Badge, Button, Box, Heading, Text, Card, List, CardHeader, CardBody, CardFooter, Link, HStack, Spacer, IconButton, Icon, Image} from '@chakra-ui/react';
import { ExternalLinkIcon, SmallCloseIcon, CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { formatNumber, setExplorer, trunkAddress } from './utils';


const PairList = ({ selectedPairs, setSelectedPairs, isEditing, selectedBundle, setSelectedBundle,setBundles, saveBundle, handleReleaseBundle, onCalculateBundle, onOpen }) => {

  useEffect(() => {
    console.log('Selected pairs:', selectedPairs);
  }, [selectedPairs]);

  useEffect(() => {
    console.log('selectedBundle changed:', selectedBundle);
  }, [selectedBundle]);

  const handleRemove = (pairToRemove) => {
    if (selectedBundle && selectedBundle.bundleArray) {
      const updatedPairs = selectedBundle.bundleArray.filter((pair) => pair !== pairToRemove);
      const updatedBundle = { ...selectedBundle, bundleArray: updatedPairs };
      setSelectedBundle(updatedBundle);
      
      setBundles((prevBundles) =>
        prevBundles.map((bundle) => {
          if (bundle._id === selectedBundle._id) {
            return updatedBundle;
          }
          return bundle;
        })
      );
    } else {
      setSelectedPairs((prevSelectedPairs) =>
        prevSelectedPairs.filter((pair) => pair !== pairToRemove)
      );
    }
  };

  const getPairUrl = (chainId, pairAddress) => {
    return `https://dexscreener.com/${chainId}/${pairAddress}`;
  };

  const getTokenExplorerUrl = (chainId, tokenAddress) => {
    const explorer = setExplorer(chainId);
    return `${explorer}${tokenAddress}`;
  };


  const showSaveButton = selectedPairs.length >= 2 && (isEditing || !selectedBundle);

  return (
    // only render if there are selected pairs
    selectedPairs.length > 0 && (
    <Box m="8px" borderRadius="8px" bg="gray.800">
      <HStack pr="8px" pt="8px" pl="24px" pb="8px">
      <Heading size="s" fontFamily="body" >
      {selectedPairs[0].baseToken.symbol} / {selectedPairs[0].quoteToken.symbol}
      </Heading>
        <Spacer />
        {showSaveButton && (
          <Button  variant="solid" size="sm" onClick={saveBundle}>
            {isEditing ? 'Save Bundle' : 'Save Bundle'}
          </Button>
        )}
        <Button size="sm" onClick={() => {
          onCalculateBundle(selectedPairs);
          onOpen();
        }}>Arbitrage</Button>
        <IconButton icon={<SmallCloseIcon/>} size="sm" onClick={handleReleaseBundle} disabled={!selectedBundle}/>
      </HStack>
      <List key={selectedPairs} pl="8px" pr="8px" pb="4px">
        {selectedPairs.map((pair, index) => (
          <Card bg="gray.900" size="sm" key={index} mb="1" direction={{ base: 'column', sm: 'column' }} overflow="hidden" variant="filled">
            <CardHeader>
            <HStack>
            <Text size="sm">
              Pool {index + 1}
            </Text>
            <Image src={`https://dd.dexscreener.com/ds-data/chains/${pair.chainId}.png`} boxSize="16px" />
            <Text>{pair.chainId}</Text>
            <Image src={`https://dexscreener.com/img/dexes/${pair.dexId}.png`} boxSize="16px" />
            <Text>{pair.dexId}</Text>
          </HStack>

                <Text pt="8px">{pair.baseToken.symbol} price: {pair.priceNative} {pair.quoteToken.symbol}</Text>
                <Text>
                24h change: {' '} 
                  <Badge colorScheme={pair.priceChange.h24 > 0 ? "green" : "red"}>
                    {pair.priceChange.h24} %
                  </Badge>
                  
                </Text>
              </CardHeader>
              <CardBody>
                <Text fontSize="sm">
                {pair.liquidity.usd > 100000 ? (
                    <Box as="span" display="inline-flex" alignItems="center">
                    <Icon color="green.400" mr="4px" as={CheckCircleIcon} />
                    </Box>
                  ) : pair.liquidity.usd > 10000 ? (
                    <Box as="span" display="inline-flex" alignItems="center">
                    <Icon color="yellow.400"  mr="4px" as={WarningIcon} />
                    </Box>
                  ) : (
                    <Box as="span" display="inline-flex" alignItems="center">
                    <Icon color="red.400" mr="4px" as={WarningTwoIcon} />
                    </Box>
                  )}
                  {formatNumber(pair.liquidity.usd)} USD - {pair.baseToken.symbol}: {formatNumber(pair.liquidity.base)} {pair.quoteToken.symbol}: {formatNumber(pair.liquidity.quote)}
                </Text>
                <Text pt="8px" fontSize="sm">
                  {pair.baseToken.symbol} address: 
                  <Link href={getTokenExplorerUrl(pair.chainId, pair.baseToken.address)} isExternal>
                    {trunkAddress(pair.baseToken.address)}
                    <ExternalLinkIcon mx="2px" />
                  </Link>
                </Text>
                <Text fontSize="sm">
                  Pair address: 
                  <Link href={getPairUrl(pair.chainId, pair.pairAddress)} isExternal>
                    {trunkAddress(pair.pairAddress)} <ExternalLinkIcon mx="2px" />
                  </Link>
                </Text>
                <Text pt="8px" fontSize="sm">Buys H1: {pair.txns.h1.buys}, H6: {pair.txns.h6.buys}, H24: {pair.txns.h24.buys}</Text>
                <Text fontSize="sm">Vol H1: {pair.volume.h1}, H6: {pair.volume.h6}, H24: {pair.volume.h24}</Text>
              </CardBody>
              <CardFooter>
                <Button size="xs" variant="solid" onClick={() => handleRemove(pair)}>Remove pool from Bundle</Button>
              </CardFooter>
          </Card>
        ))}
      </List>
    </Box>
    )
  );
};

export default PairList;
