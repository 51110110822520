import React, { useState, useEffect } from 'react';

import Search from './Search';
import PairList from './PairList';
import Calculate from './Calculate';
import SavedBundles from './SavedBundles';
import NavBar from './NavBar';
import { usePrivy } from '@privy-io/react-auth';

import { ChakraProvider, Grid, GridItem, List, useDisclosure, useToast, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter, Button, Text } from '@chakra-ui/react';
import styled from "@emotion/styled";
import '@fontsource-variable/mulish';

import theme from './theme';

import axios from 'axios';
import { set } from 'mongoose';


const AppContainer = styled.div`
  font-family: 'Mulish Variable', sans-serif;
  `;

const App = () => {
  const [selectedPairs, setSelectedPairs] = useState([]);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [bundles, setBundles] = useState([]);
  const [editingBundle, setEditingBundle] = useState([]);
  const [onCalculateBundle, setOnCalculateBundle] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);




  const { user } = usePrivy();

  const { isOpen, onOpen, onClose } = useDisclosure();


  // state management //
  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (!hasVisited) {
      setIsFirstVisit(true); // Show the modal for first-time visitors
      localStorage.setItem("hasVisited", "true");
    }
  }, []);
  
  

  useEffect(() => {
    const fetchBundles = async () => {
      try {
        const response = await axios.get(`https://shiftdown-sl-be.vercel.app/api/getAllBundles?did=${user.id}`);
        setBundles(response.data);
      } catch (error) {
        console.error('Error fetching bundles:', error);
      }
    };

    if (user && user.id) {
        fetchBundles();
    }
}, [user]);

const toggleHelpModal = () => {
  console.log('toggleHelpModal');
  setIsFirstVisit(true)
};


const fetchBundles = async () => {
  try {
    const response = await axios.get(`https://shiftdown-sl-be.vercel.app/api/getAllBundles?did=${user.id}`);
    setBundles(response.data);
  } catch (error) {
    console.error('Error fetching bundles:', error);
  }
};

  // addind a pair from search to the pairList
  const addPairToList = (pair) => {
    setSelectedPairs((prevSelectedPairs) => [...prevSelectedPairs, pair]);
  };

  // clear all pairs from the pairList
  const handleReleaseBundle = () => {
    if (!selectedBundle) {
      setSelectedPairs([]);
      console.log('Clear selected pairs');
      return;
    }
    console.log('Releasing bundle:', selectedBundle);
    // unset selected bundle and clear selected pairs
    setSelectedBundle(null);
    setSelectedPairs([]);
    console.log('Bundle released:', selectedBundle.bundleName);
  };

  // handle updating a bundle
  const handleUpdateBundle = (updatedBundle) => {
    setSelectedPairs(updatedBundle.bundleArray);
    setSelectedBundle(updatedBundle);
};

 const toast = useToast();

const saveBundleToast = () => { 
  toast({
    title: `Bundle saved.`,
    status: 'info',
    bg: 'green.500',
    duration: 2000,
    position: 'bottom-left',
    isClosable: true,
  });
};

const showLoginToast = () => {
  toast({
    title: `Login to save bundles.`,
    status: 'info',
    duration: 2000,
    bg: 'red.500',
    position: 'bottom-left',
    isClosable: true,
  });
};


  // handle saving a bundle
  const saveBundle = async () => {
    // check if the user is logged in
    if (!user || !user.id) {
      console.log('User not logged in');
      showLoginToast();
      return;
    }
    try {
      let response;
      if (selectedBundle && selectedBundle._id) {
        response = await axios.patch(`https://shiftdown-sl-be.vercel.app/api/updateBundle/${selectedBundle._id}`, {
          bundleName: selectedBundle.bundleName,
          bundleArray: selectedPairs,
          did: user.id
        });
        console.log('Bundle updated:', response.data);
      } else {
        response = await axios.post('https://shiftdown-sl-be.vercel.app/api/createBundle', {
          bundleName: selectedPairs[0].baseToken.symbol + '/' + selectedPairs[0].quoteToken.symbol,
          bundleArray: selectedPairs,
          did: user.id
        });
        console.log('Bundle saved:', response.data);
      }
  
      // Clear the selected bundle
      setSelectedBundle(null);
      
      // Get the new list of saved bundles
      //const bundleListResponse = await axios.get('/api/bundles');
  
      // Update the bundles state to trigger a re-render
      //setBundles(bundleListResponse.data);

      fetchBundles();


      saveBundleToast();
  
    } catch (error) {
      console.error('Error saving bundle:', error);
    }
  };

  // handle calculating a bundle
  const calculateBundle = (selectedPairs) => {
    console.log('Calculating bundle:', selectedPairs);
    setOnCalculateBundle(true);
    setSelectedPairs(selectedPairs);
  }

  return (
    <ChakraProvider theme={theme}>
      <AppContainer>
      {isFirstVisit && (
          <Modal isOpen={isFirstVisit} onClose={() => setIsFirstVisit(false)}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Welcome to Pool Watcher</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize="md" mb="16px">Here is how Pool Watcher works:</Text>
                <Text fontSize="sm" mb="8px">1. Search for a token and add it to a Bundle</Text>
                <Text fontSize="sm" mb="8px">2. Add more pools with the same tokenpair to your Bundle</Text>
                <Text fontSize="sm" mb="8px">3. Save your Bundle by clicking on the Save button</Text>
                <Text fontSize="sm" mb="8px">4. Calculate arbitrage within your Bundles</Text>
              </ModalBody>
              <ModalFooter>
              <Button onClick={() => setIsFirstVisit(false)}>Got it!</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
        <NavBar setBundles={setBundles} onToggleHelpModal={toggleHelpModal}/>
        <Grid 
templateColumns={{ base: "1fr", md: selectedPairs.length > 0 ? "1fr 1fr auto" : "1fr auto" }}
templateRows={{ base: "min-content min-content 1fr", md: "1fr" }}
  gridTemplateAreas={{
    base: `
      "savedbundles"
      "pairlist"
      "search"
    `,
    md: selectedPairs.length > 0 ? `
      "search pairlist savedbundles"
    ` : `
      "search savedbundles"
    `
  }}
  bg="#0e0e0e"
  minHeight="100vh"
>
  <GridItem gridArea="search">
    <Search
      selectedPairs={selectedPairs}
      addPairToList={addPairToList}
    />
    <Calculate
      selectedPairs={selectedPairs}
      onCalculateBundle={calculateBundle}
      onOpen={onOpen} 
      isOpen={isOpen}
      onCloseModal={onClose}
    />
  </GridItem>
  <GridItem gridArea="pairlist" display={{ base: selectedPairs.length > 0 ? "block" : "none", md: "block" }}>
    <PairList
        setBundles={setBundles}
        saveBundle={saveBundle}
        selectedBundle={editingBundle}
        setSelectedBundle={setEditingBundle}
        selectedPairs={selectedPairs}
        handleReleaseBundle={handleReleaseBundle}
        setSelectedPairs={setSelectedPairs}
        isEditing={editingBundle !== null}
        onCalculateBundle={calculateBundle}
        onOpen={onOpen}
      />
  </GridItem>
  <GridItem gridArea="savedbundles" w={{ base: "100%", md: "140px" }} >
    <List>
      <SavedBundles
      onUpdateBundle={handleUpdateBundle}
      setBundles={setBundles}
      bundles={bundles}
      />
    </List>
  </GridItem>
</Grid>
      </AppContainer>
    </ChakraProvider>
  );
};

export default App;
