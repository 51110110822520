import { Button, Flex, HStack, Spacer, Text, Image, Icon} from "@chakra-ui/react";
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import React from "react";
import { usePrivy } from '@privy-io/react-auth';
import { trunkAddress } from './utils';

export default function NavBar( {setBundles, onToggleHelpModal} ) {
  
  const [loggedInAccount, setLoggedInAccount] = React.useState("");
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);


  const {login: privyLogin, logout: privyLogout, user: privyUser, authenticated} = usePrivy();


  React.useEffect(() => {
    if (authenticated && privyUser?.wallet?.address) {
      setLoggedInAccount(trunkAddress(privyUser.wallet.address));
      setIsLoggedIn(true);
    } else {
      setLoggedInAccount("");
      setIsLoggedIn(false);
    }
  }, [authenticated, privyUser]);
  


  // Handle login and logout - set loggedInAccount and isLoggedIn
  const handleLogin = async () => {
    console.log("handleLogin");
    if (!isLoggedIn) {
      await privyLogin(); // This does not return user data
      // The user data should now be available in privyUser after a brief moment
      // You might need to handle this asynchronously or set up a listener
    } else {
      await privyLogout();
      setLoggedInAccount("");
      setIsLoggedIn(false);
      setBundles([]);
      console.log("logged out");
    }
  };
  

  return (
    <>
    <Flex alignItems="center" as="nav" p="10px" justify="space-around" bgColor="#0e0e0e">
    <Image src="/poolwatcher_light.png" ml="8px" w="120px"/>
      <Spacer />
      <HStack spacing="20px">
        <Icon as={QuestionOutlineIcon} onClick={onToggleHelpModal} />
        <Text>{isLoggedIn ? loggedInAccount : ""}</Text>
        <Button onClick={handleLogin} variant="outline">
          {isLoggedIn ? 'Logout' : 'Login'}
        </Button>
      </HStack>
    </Flex>
     
     </>

  );
}
