import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Text, Heading, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Box, Card, CardHeader, SimpleGrid, CardBody, Flex, Image, HStack, Spacer, Link, Icon, useBreakpointValue } from '@chakra-ui/react';
import { ExternalLinkIcon, CheckCircleIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { formatNumber, setExplorer } from './utils';

  const getTokenExplorerUrl = (chainId, tokenAddress) => {
    const explorer = setExplorer(chainId);
    return `${explorer}${tokenAddress}`;
  };


const Calculate = ({ isOpen, selectedPairs, onOpen, onCloseModal, onCalculateBundle }) => {

    const isSmallScreen = useBreakpointValue({ base: true, md: false });

    const [quoteToken, setQuoteToken] = useState('');
    const [baseToken, setBaseToken] = useState('');

    const [venue1, setVenue1] = useState('');
    const [venue1ChainId, setVenue1ChainId] = useState(null);
    const [venue1Price, setVenue1Price] = useState(null);
    const [venue1PairAddress, setVenue1PairAddress] = useState('');
    const [venue1Liquidity, setVenue1Liquidity] = useState(null);

    const [venue2, setVenue2] = useState('');
    const [venue2ChainId, setVenue2ChainId] = useState(null);
    const [venue2Price, setVenue2Price] = useState(null);
    const [venue2Liquidity, setVenue2Liquidity] = useState(null);
    const [venue2PairAddress, setVenue2PairAddress] = useState('');

    useEffect(() => {
      const fetchData = async () => {
        try {
          if (selectedPairs.length === 0 || !selectedPairs) { 
            return;
          }
    
          const pairPrices = await Promise.all(
            selectedPairs.map(async (pair) => {
              const response = await axios.get(
                `https://api.dexscreener.com/latest/dex/pairs/${pair.chainId}/${pair.pairAddress}`
              );
              return response.data.pairs[0];
            })
          );
    
          const sortedPairPrices = pairPrices.sort(
            (a, b) => a.priceNative - b.priceNative
          );
    
          console.log('Sorted pair prices:', sortedPairPrices[0], sortedPairPrices[sortedPairPrices.length - 1]);
          const cheapestPair = sortedPairPrices[0];
          const expensivePair = sortedPairPrices[sortedPairPrices.length - 1];
    
          setQuoteToken(cheapestPair.quoteToken.symbol);
          setBaseToken(cheapestPair.baseToken.symbol);
    
          setVenue1(cheapestPair.dexId);
          setVenue1ChainId(cheapestPair.chainId);
          setVenue1Liquidity(cheapestPair.liquidity.usd);
          setVenue1Price(cheapestPair.priceNative);
          setVenue1PairAddress(cheapestPair.pairAddress);
    
          setVenue2(expensivePair.dexId);
          setVenue2ChainId(expensivePair.chainId);
          setVenue2Price(expensivePair.priceNative);
          setVenue2Liquidity(expensivePair.liquidity.usd);
          setVenue2PairAddress(expensivePair.pairAddress);
          
        } catch (error) {
          console.error('Error retrieving pair data:', error);
        }
      };
    
      if (selectedPairs.length > 0) {
        fetchData();
        const interval = setInterval(fetchData, 1000000);
    
        return () => clearInterval(interval);
      }
    }, [selectedPairs]);


  // Mapping ModalContent components based on selectedPairs
  const modalContents = selectedPairs.map((pair, index) => (
    <ModalContent key={index} bg="gray.800">
      <ModalHeader>Calculation: {baseToken}/{quoteToken}</ModalHeader>
      <ModalCloseButton />
      <Flex justify="space-around" p="24px">
            <Text fontSize="xl" pl="24px" pr="24px">Price difference: {((venue2Price - venue1Price) / venue1Price * 100).toFixed(2)}%</Text>
        </Flex>
        <Flex justify="space-around" p="4px">
        <Text fontSize="xl" pl="24px" pr="24px">{venue1ChainId !== venue2ChainId ? ( "Cross chain - bridging need" ) : ( "" )}</Text>
        </Flex>
      <ModalBody>
        <SimpleGrid columns={2} spacingX='0px'>
            <Card variant="outline" p="8px" m="8px" bg="gray.900">
                <CardHeader>    
                    <HStack>                
                        <Heading size="md">
                            Buy {baseToken} at {venue1}              
                        </Heading>
                        <Spacer />
                        <Image src={`https://dexscreener.com/img/dexes/${venue1}.png`} boxSize="32px"/>
                    </HStack>
                    <Text>on {venue1ChainId}</Text>
                </CardHeader>
                <CardBody>
                <Text pb="8px">Price: {venue1Price} {quoteToken}/{baseToken}</Text>
                <Box display="inline-flex" alignItems="center">
                  {venue1Liquidity > 100000 ? (
                    <Icon color="green.400" mr="4px" as={CheckCircleIcon} />
                  ) : venue1Liquidity > 10000 ? (
                    <Icon color="yellow.400" mr="4px" as={WarningIcon} />
                  ) : (
                    <Icon color="red.400" mr="4px" as={WarningTwoIcon} />
                  )}
                  <Text fontSize="sm" lineHeight="normal">
                    Liquidity: {formatNumber(venue1Liquidity)} USD
                  </Text>
                </Box>

                <Link fontSize="xs"  href={getTokenExplorerUrl(pair.chainId, pair.baseToken.address)} isExternal>
                {venue1PairAddress}
                <ExternalLinkIcon mx='2px' />
                </Link>
                </CardBody>
            </Card>
            <Card variant="outline" p="8px" m="8px" bg="gray.900">
                <CardHeader>
                    <HStack>
                    <Heading size="md">
                    Sell {baseToken} at {venue2}
                    </Heading>
                    <Spacer />
                    <Image src={`https://dexscreener.com/img/dexes/${venue2}.png`} boxSize="32px"/>
                    </HStack>
                    <Text>on {venue2ChainId}</Text>
                </CardHeader>
                <CardBody>  
                <Text pb="8px">Price: {venue2Price} {quoteToken}/{baseToken}</Text>
                <Box display="inline-flex" alignItems="center">
                  {venue2Liquidity > 100000 ? (
                    <Icon color="green.400" mr="4px" as={CheckCircleIcon} />
                  ) : venue2Liquidity > 10000 ? (
                    <Icon color="yellow.400" mr="4px" as={WarningIcon} />
                  ) : (
                    <Icon color="red.400" mr="4px" as={WarningTwoIcon} />
                  )}
                  <Text fontSize="sm" lineHeight="normal">
                    Liquidity: {formatNumber(venue2Liquidity)} USD
                  </Text>
                </Box>
                <Link fontSize="xs"  href={getTokenExplorerUrl(pair.chainId, pair.baseToken.address)} isExternal>
                {venue2PairAddress}
                <ExternalLinkIcon mx='2px' />
                </Link>
                </CardBody>
            </Card>
        </SimpleGrid>
      </ModalBody>
      <ModalFooter>
      </ModalFooter>
    </ModalContent>
  ));
  return (
    <Box>
     {isSmallScreen ?
    <Modal size="md" isOpen={isOpen} onClose={onCloseModal}>
    <ModalOverlay />
    {modalContents}
  </Modal>
  :
  <Modal size="4xl" isOpen={isOpen} onClose={onCloseModal}>
    <ModalOverlay />
    {modalContents} 
  </Modal>
  }
</Box>
  );
};

export default Calculate;
