import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ColorModeScript } from '@chakra-ui/react'
import theme from './theme'
import { PrivyProvider } from '@privy-io/react-auth';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PrivyProvider
        appId={"clk723z9f00cbla08rs3tdpjq"}
        onSuccess={user => { console.log('User logged in:', user) }}
        config={{
          loginMethods: ['email', 'wallet'],
          appearance: {
            theme: 'dark',
            accentColor: '#00FFB0',
            logo: 'https://www.poolwatcher.xyz/pw_logo_white.svg',
          },
        }}
      >
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <App />
    </PrivyProvider>
  </React.StrictMode>
);

