import { extendTheme } from '@chakra-ui/react'


const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
  styles: {
    global: () => ({
      body: {
        fontFamily: '"Mulish Variable", sans-serif',
        bg: "gray.900",
      },
      heading: {
        fontFamily: '"Mulish Variable", sans-serif',
        bg: "green",
      },
    })
  }
}


// 3. extend the theme
const theme = extendTheme({ config })

export default theme