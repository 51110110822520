export const formatNumber = (number) => {
  if (number == null) {  // This checks for both null and undefined
    return 'N/A';       // Return a default value for null or undefined numbers
  }
  if (number >= 1e6) {
    return (number / 1e6).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' M';
  } else {
    return number.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
};


export const trunkAddress = (str) => {
  if (str.length <= 8) {
    return str; // Return the original string if its length is 8 or less
  }
  return str.slice(0, 4) + "..." + str.slice(-4);
};

export const setExplorer = (chainId) => {
    switch (chainId) {
      case "ethereum":
        return 'https://etherscan.io/token/'; 
      case "arbitrum":
        return 'https://arbiscan.io/token/';
      case "optimism":
        return 'https://optimistic.etherscan.io/token/';
      case "bsc":
        return 'https://bscscan.com/token/';
      case "polygon":
        return 'https://polygonscan.com/token/';
      case "fantom":
        return 'https://ftmscan.com/token/';
      case "avalanche":
        return 'https://cchain.explorer.avax.network/tokens/';
      case "heco":
        return 'https://hecoinfo.com/token/';
      case "harmony":
        return 'https://explorer.harmony.one/#/address/';
      case "celo":
        return 'https://explorer.celo.org/address/';
      case "moonriver":
        return 'https://blockscout.moonriver.moonbeam.network/address/';
      case "palm":
        return 'https://explorer.palm.io/#/address/';
      case "kcc":
        return 'https://explorer.kcc.io/en/token/';
      case "xdai":
        return 'https://blockscout.com/xdai/mainnet/tokens/';
      case "zkswap":
        return 'https://zkswap.info/en/token/';
      case "zksync":
        return 'https://explorer.zksync.io/address/';
      case "polygonzkevm":
        return 'https://zkevm.polygonscan.com/address/';
      case "solana":
        return 'https://explorer.solana.com/address/';
      default:
        return 'https://etherscan.io/address/';
    }
  }
