
import { DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';
import {
  Box,
  Card,
  Stack,
  IconButton,
  Text,
  Spacer,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { useState } from 'react';

const SavedBundles = ({ onUpdateBundle, setBundles, bundles }) => {
  const { user, login, logout } = usePrivy();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [bundleToDelete, setBundleToDelete] = useState(null);

  const initiateDeleteBundle = (event, bundleId) => {
    event.stopPropagation();
    setBundleToDelete(bundleId);
    onOpen();
  };

  const confirmDeleteBundle = async () => {
    try {
      await axios.delete(`https://shiftdown-sl-be.vercel.app/api/deleteBundle/${bundleToDelete}?did=${user.id}`);
      
      setBundles((prevBundles) => prevBundles.filter((bundle) => bundle._id !== bundleToDelete));
      showBundleDeletedToast();
    } catch (error) {
      console.error('Error deleting bundle:', error);
    }
    onClose();
  };


  const handleDeleteBundle = async (event, bundleId) => {
    event.stopPropagation();
    try {
      await axios.delete(`https://shiftdown-sl-be.vercel.app/api/deleteBundle/${bundleId}`, {
        data: { did: user.id }
      });
      
      setBundles((prevBundles) => prevBundles.filter((bundle) => bundle._id !== bundleId));
      showBundleDeletedToast();
    } catch (error) {
      console.error('Error deleting bundle:', error);
    }
  };

  useEffect(() => {
    console.log('refreshing bundles');
  }, [bundles, user, login, logout]);


  const showBundleDeletedToast = () => {
    toast({
      title: `Bundle deleted.`,
      status: 'info',
      duration: 2000,
      bg: 'red.500',
      position: 'bottom-left',
      isClosable: true,
    });
  };


  return (
    <Box
    pt="4px"
    pr="4px"
    pl="4px">
        {bundles.length === 0 ? (
        <Text fontSize="sm" pb="4px"> No saved bundles</Text>
        )  : (
      bundles.map((bundle) => {
      const handleCardClick = () => {
        console.log('Bundle selected:', bundle);
        if (bundle.bundleArray) {
          onUpdateBundle(bundle);
        }
      };
        return (
          <Card
          p="2px"
          m="4px"
          key={bundle._id}
          onClick={handleCardClick}
          cursor="pointer"
          bgGradient='linear(to-b, gray.700, gray.900)'
          _hover={{
            bgGradient: 'linear(to-b, gray.800, gray.900)',
          }}
          >
          <Stack direction="row">
            <Text pl="4px" pt="4px" pb="4px" fontSize="xs" whiteSpace="pre-line">
              {bundle.bundleName} {"\n"} ({bundle.bundleArray.length} pools)
            </Text>
            <Spacer />
            <Box m="4px">
            <IconButton icon={<DeleteIcon/>} size="xs" onClick={(event) => initiateDeleteBundle(event, bundle._id) }/>
            </Box>
          </Stack>
        </Card>
      )}
      ))}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete bundle</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Do you want to delete the bundle?</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme='red' mr={3} onClick={confirmDeleteBundle}>
              Yes, delete it
            </Button>
            <Button variant='ghost' onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SavedBundles;
